import React, { useState, useEffect } from "react";
import '../styles/smsHistoryStyle.css'
import { getRequestOptions, postRequestOptions } from '../helpers/Fetchwrapper'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

const SmsHistory = () => {

    const [smsData, setSmsData] = useState([]);
    const [search, setSearch] = useState("");
    const [activeTab, setActiveTab] = useState("All");
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
  
    useEffect(() => {
      fetchSmsData();
    }, [search, activeTab, page]);
  
    const fetchSmsData = async () => {
      try {
        const queryParams = new URLSearchParams({
          search,
          type: activeTab !== "All" ? activeTab.toLowerCase() : "",
          page,
        }).toString();
  
        const response = await fetch(`${process.env.REACT_APP_API_URL}sms_history?${queryParams}`);
        if (!response.ok) {
          throw new Error("Failed to fetch SMS data");
        }
  
        const data = await response.json();
        setSmsData(data.data);
        setTotalPages(data.last_page);
      } catch (error) {
        console.error("Error fetching SMS data:", error);
      }
    };
  
    const handleTabChange = (tab) => {
      setActiveTab(tab);
      setPage(1); // Reset to the first page on tab change
    };
  
    const handleSearchChange = (e) => {
      setSearch(e.target.value);
      setPage(1); // Reset to the first page on new search
    };
  
    const handlePageChange = (newPage) => {
      if (newPage > 0 && newPage <= totalPages) {
        setPage(newPage);
      }
    };
  

  return (
    <div className='card card-custom'>
      <div className='card-header'>
        <h3 className='card-title'>SMS History</h3>
      </div>

      <div className='card-body'>
        <div style={{ marginLeft: '-12.5px', marginRight: '-12.5px' }}>
  
            <div className="sms-container">

                {/* Tabs */}
                <div className="sms-tabs">
                {["All", "NETBEE", "NETBEEBILL", "NETBEE INFO"].map((tab) => (
                    <a
                    key={tab}
                    className={activeTab === tab ? "active" : ""}
                    onClick={() => handleTabChange(tab)}
                    >
                    {tab}
                    </a>
                ))}
                </div>

                {/* Search Bar */}
                <div className="sms-search-bar">
                
                <input
                    type="text"
                    placeholder="Search..."
                    value={search}
                    onChange={handleSearchChange}
                />
                </div>

                {/* SMS Items */}
                <div>
                {smsData.map((sms) => (
                    <div key={sms.id} className="sms-item">
                    <div className="sms-item-top"> 
                    <h3>{sms.name}</h3>
                    <span>{sms.date}</span>
                    <span className="sms-type">{sms.sms_status === 'send' && <><span className="svg-icon svg-icon-2x svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Done-circle.svg"
                )}
              ></SVG>
            </span></>} </span>
                    </div>
                    <p>{sms.sms_body}</p>
                   
                    </div>
                ))}
                </div>

                {/* Pagination */}
                <div className="sms-pagination">
                <button
                    onClick={() => handlePageChange(page - 1)}
                    disabled={page === 1}
                >
                    Previous
                </button>
                <span>
                    Page {page} of {totalPages}
                </span>
                <button
                    onClick={() => handlePageChange(page + 1)}
                    disabled={page === totalPages}
                >
                    Next
                </button>
                </div>
            </div>


        </div>
      </div>
    </div>
  )
}
export default SmsHistory
