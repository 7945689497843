import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import DashboardWidget from "../components/DashboardWidget";
import Zonewisecustomer from "../components/ZonewiseCustomer";
import CustomerDueReport from "./reports/CustomerDueReport";
import CustomerBalance  from './CustomerBalance';

export function DashboardPage() {
  return (
    <>
    <DashboardWidget/>
    <CustomerBalance/>
    <Zonewisecustomer/>
    </>
  );


}
