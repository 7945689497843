/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import PerfectScrollbar from "react-perfect-scrollbar";
import { toAbsoluteUrl } from "../../../../../_helpers";
import { da } from "date-fns/locale";
import { Link } from 'react-router-dom';

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

export function SearchResult({ data }) {
  if (!data) {
    return null;
  }

  if (data.length === 0) {
    return (
      <div
        style={{ maxHeight: "325px", overflow: "hidden" }}
        className="quick-search-wrapper scroll ps ps--active-y"
      >
        <div className="quick-search-result">
          <div className="text-muted d-none">No record found</div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{ maxHeight: "325px", overflow: "hidden" }}
      className="quick-search-wrapper scroll ps ps--active-y"
    >
      <PerfectScrollbar
        options={perfectScrollbarOptions}
        className="scroll"
        style={{ maxHeight: "325px", position: "relative" }}
      >
        <div className="quick-search-result">
   

          {/* begin: Section */}
          <div className="font-size-sm text-primary font-weight-bolder text-uppercase mb-2">
            Customers
          </div>
          <div className="mb-10">

            { data.map((customer) => (
           
            
            <Link to={`/customer-details/${customer.id}`}>
              <div className="customer-card ">
                <div className="customer-header">
                    <span>ID {customer.code}</span>
                    <span>{customer.name}<br/>
                        {customer.mobileNo}
                    </span>
                    <span>Balance ৳{customer.balance}</span>
                </div>
                <div className="customer-info">
                    <p>
                        {customer.address}
                    </p>
                </div>
            </div>
            </Link>
              
           
            ))}
          
          </div>
          {/* end: Section */}

  
        </div>
      </PerfectScrollbar>
    </div>
  );
}
