import React, { Component } from 'react';
import DataTable from '../../components/DataTable'

const CustomerDueReport = () => {

    const url = process.env.REACT_APP_API_URL + "reports/customer_due";

    const columns = [
        { 'value': 'id', 'title': 'Id' },
        { 'value': 'name', 'title': 'Name' },
        { 'value': 'code', 'title': 'Code' },
        { 'value': 'mobileNo', 'title': 'Mobile No' },
        { 'value': 'area', 'title': 'Area' },
        { 'value': 'zone', 'title': 'Zone' },
        { 'value': 'balance', 'title': 'Balance' }
      ];

    const filters = {
        'id': {
            filterType: 'like',
            filterValue: ''
          },
          'name': {
            filterType: 'like',
            filterValue: ''
          },
          'code': {
            filterType: 'like',
            filterValue: ''
          },
          'mobileNo': {
            filterType: 'like',
            filterValue: ''
          },
          'area': {
            filterType: 'like',
            filterValue: ''
          },
          'zone': {
            filterType: 'like',
            filterValue: ''
          },
          'balance': {
            filterType: 'like',
            filterValue: ''
          }
    }


    return (
        <div className="card card-custom">

                <div className="card-header">
                    <h3 className="card-title">Customer  Due Report</h3>
                </div>
                
                <div className="card-body">
                    <div style={{marginLeft:"-12.5px",marginRight:"-12.5px"}}>
                    <DataTable url={url} columns={columns} filters={filters} />
                    </div>
                </div>

            </div>

    );
}

export default CustomerDueReport
