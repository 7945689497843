/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/setup", true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/setup">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Settings4.svg")}
              />
            </span>
            <span className="menu-text">Setup</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Setup</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/setup-area")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/setup-area">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Area Setup</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/setup-zone")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/setup-zone">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Zone Setup</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/setup-package")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/setup-package">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Package Setup</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/customer", true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/customer">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} />
            </span>
            <span className="menu-text">Customer</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Customer</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/customer-reg"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/customer-reg">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Add-user.svg")} />
                  </span>
                  <span className="menu-text">Create Customer</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/customer-network"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/customer-network">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Router1.svg")} />
                  </span>
                  <span className="menu-text">Customer Network Info</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  "/customer-search"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/customer-search">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")} />
                  </span>
                  <span className="menu-text">Customer Search</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/customer-list"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/customer-list">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Bullet-list.svg")} />
                  </span>
                  <span className="menu-text">Customer List</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}



        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/user", true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/user">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")} />
            </span>
            <span className="menu-text">User</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text"> User</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/user-create"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/user-create">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Create User</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/user-list"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/user-list">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">User List </span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/bill", true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/bill">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Book.svg")} />
            </span>
            <span className="menu-text">Billing</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Billing</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/bill-generates"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/bill-generates">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Book-open.svg")} />
                  </span>
                  <span className="menu-text">Billing Generate</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
                {/*begin::2 Level*/}
                <li
                className={`menu-item ${getMenuItemActive(
                  "/bill-create"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/bill-create">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Book-open.svg")} />
                  </span>
                  <span className="menu-text">Billing Create</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/bill-list"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/bill-list">
                <span className="svg-icon menu-icon">
										<SVG src={toAbsoluteUrl("/media/svg/icons/Text/Bullet-list.svg")} />
									</span>
                  <span className="menu-text">Bill List </span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/payment", true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/payment">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")} />
            </span>
            <span className="menu-text">Payment</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Payment</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/payment-collection"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/payment-collection">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Dollar.svg")} />
                  </span>
                  <span className="menu-text">Payment  Collection</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/payment-list"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/payment-list">
                <span className="svg-icon menu-icon">
										<SVG src={toAbsoluteUrl("/media/svg/icons/Text/Bullet-list.svg")} />
									</span>
                  <span className="menu-text">Payment List </span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/sms", true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/sms">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Send.svg")} />
            </span>
            <span className="menu-text">SMS</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">SMS</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/sms-send-zonewise"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/sms-send-zonewise">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Book-open.svg")} />
                  </span>
                  <span className="menu-text">Zone Wise SMS</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
                <li
                className={`menu-item ${getMenuItemActive(
                  "/sms-send-customer"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/sms-send-customer">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Send.svg")} />
                  </span>
                  <span className="menu-text">Send SMS Customer</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

               {/*begin::2 Level*/}
               <li
                className={`menu-item ${getMenuItemActive(
                  "/sms-template"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/sms-template">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Send.svg")} />
                  </span>
                  <span className="menu-text"> SMS Template </span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

                {/*begin::2 Level*/}
                 <li
                className={`menu-item ${getMenuItemActive(
                  "/sms-history"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/sms-history">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Send.svg")} />
                  </span>
                  <span className="menu-text"> SMS History</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

         {/*begin::1 Level*/}
         <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/report", true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/report">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Book-open.svg")} />
            </span>
            <span className="menu-text">Reports</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Reports</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  "/report-customer-collection"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/report-customer-collection">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Book-open.svg")} />
                  </span>
                  <span className="menu-text">Customer Collection Report </span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
                <li
                className={`menu-item ${getMenuItemActive(
                  "/report-customer-due"
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/report-customer-due">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Book-open.svg")} />
                  </span>
                  <span className="menu-text">Customer Due  Report</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

            </ul>
          </div>
        </li>
        {/*end::1 Level*/}


      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
