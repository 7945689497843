import React, { useState, useEffect,useRef } from "react";
import { useParams } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { QRCodeSVG } from 'qrcode.react';
import { toPng } from "html-to-image";
import { saveAs } from "file-saver";

const PaymentDetails = () => {
    const { id: paymentId } = useParams();
    const printRef = useRef(); // Ref for the element to print
    const [paymentDetails, setPaymentDetails] = useState({
        customerName: "",
        phone: "",
        customerId: "",
        customerCode: "",
        address: "",
        paymentDate: "",
        paymentAccount: "",
        paidAmount: "",
        unpaidAmount: "",
        lastPaymentDate: "",
        lastPaymentAmount: "",
    });

    useEffect(() => {
        getPaymentDetails(paymentId);
    }, [paymentId]);

    const getPaymentDetails = (id) => {
        fetch(`${process.env.REACT_APP_API_URL}payment_details/${id}`, {
            method: "GET",
        })
            .then((response) => response.json())
            .then((data) => {
                setPaymentDetails({
                    customerName: data.customerName,
                    phone: data.phone,
                    customerId: data.customerId,
                    customerCode: data.customerCode,
                    address: data.address,
                    paymentDate: data.paymentDate,
                    paymentAccount: data.ledgerName,
                    paidAmount: Number(data.amount),
                    unpaidAmount: Number(data.unpaidAmount),
                    lastPaymentDate: data.lastPaymentDate,
                    lastPaymentAmount: data.lastPaymentAmount,
                });
            })
            .catch((error) => {
                console.error("Error fetching payment details:", error);
            });
    };

    const handlePrint =  () =>{
        const originalContents = document.body.innerHTML;
        const printContents = printRef.current.innerHTML;
    
        // Temporarily replace body contents with the div to print
        document.body.innerHTML = printContents;
        window.print();
    
        // Restore original contents
        document.body.innerHTML = originalContents;
        window.location.reload(); // Reload to re-render the page properly
        
      }

      const handleDownload = () => {
        const node = printRef.current;
        // Get the device's screen width and use it for scaling if necessary
        const containerWidth = window.innerWidth; // Fallback to screen width if offsetWidth is 0
        const containerHeight = node.offsetHeight || (containerWidth * (node.offsetHeight / node.offsetWidth)); // Maintain aspect ratio

        toPng(node, { width: containerWidth, height: containerHeight }) // Dynamically adjust dimensions
            .then((dataUrl) => {
            const link = document.createElement("a");
            link.download = `${paymentId}_receipt.png`;
            link.href = dataUrl;
            link.click();
            })
            .catch((error) => console.error("Error generating image:", error));
        };

    const qrCodeValue = `https://billing.netbeebd.com/payment-details/${paymentId}`;

    const convertNumberToWords = (num) => {
        if (!num || isNaN(num)) return false;
    
        num = num.toString().replace(/[, ]/g, '').trim();
        num = parseInt(num, 10);
    
        const list1 = [
            '',
            'one',
            'two',
            'three',
            'four',
            'five',
            'six',
            'seven',
            'eight',
            'nine',
            'ten',
            'eleven',
            'twelve',
            'thirteen',
            'fourteen',
            'fifteen',
            'sixteen',
            'seventeen',
            'eighteen',
            'nineteen',
        ];
    
        const list2 = ['', 'ten', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety', 'hundred'];
    
        const list3 = [
            '',
            'thousand',
            'million',
            'billion',
            'trillion',
            'quadrillion',
            'quintillion',
            'sextillion',
            'septillion',
            'octillion',
            'nonillion',
            'decillion',
            'undecillion',
            'duodecillion',
            'tredecillion',
            'quattuordecillion',
            'quindecillion',
            'sexdecillion',
            'septendecillion',
            'octodecillion',
            'novemdecillion',
            'vigintillion',
        ];
    
        const numStr = num.toString();
        const numLength = numStr.length;
        const levels = Math.ceil(numLength / 3); // Determine the number of levels
        const paddedNum = numStr.padStart(levels * 3, '0'); // Pad the number with leading zeros
        const numLevels = paddedNum.match(/.{1,3}/g); // Split into groups of 3 digits
        const words = [];
    
        numLevels.forEach((level, index) => {
            const levelNum = parseInt(level, 10);
            const hundreds = Math.floor(levelNum / 100);
            const tensAndOnes = levelNum % 100;
            const tens = Math.floor(tensAndOnes / 10);
            const ones = tensAndOnes % 10;
    
            let levelWords = '';
    
            if (hundreds > 0) {
                levelWords += `${list1[hundreds]} hundred `;
            }
    
            if (tensAndOnes < 20) {
                levelWords += `${list1[tensAndOnes]} `;
            } else {
                levelWords += `${list2[tens]} `;
                if (ones > 0) {
                    levelWords += `${list1[ones]} `;
                }
            }
    
            const levelName = list3[levels - index - 1];
            if (levelNum > 0 && levelName) {
                levelWords += `${levelName} `;
            }
    
            words.push(levelWords.trim());
        });
    
        return words.join(' ').trim();
    };

    return (
        <>
         <div className="text-center" style={{ marginBottom: "20px",display:'flex',flexDirection: 'row',justifyContent:'center' }}>
            
            <button
              onClick={handleDownload}
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
            ><span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Design/Image.svg"
                    )}
                 ></SVG>
                 </span>
            </button>
            <button
              onClick={handlePrint}
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
            ><span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Devices/Printer.svg"
                    )}
                 ></SVG>
                 </span>
            </button>
          </div>
        <div className="card card-custom receipt-div" ref={printRef}>
              <div className="text-center mb-4">
                <SVG src={toAbsoluteUrl("/media/logo.svg"
                )}width="110" ></SVG>
            </div>
            {/* <div className="header">
                <div className="customer-info">
                    <h2>{paymentDetails.customerName}</h2>
                    <p>{paymentDetails.phone}</p>
                    <p>ID {paymentDetails.customerId}</p>
                </div>
            </div> */}

            <div className="receipt-section-top-head">
      
            <h3 style={{ fontSize: "1.2rem", marginBottom: "5px" }}>{paymentDetails.customerName}</h3>
                
            </div>
            <div className="receipt-section-top">
                <h3 style={{ fontSize: "1.2rem", marginBottom: "5px" }}>Receipt</h3>
                <h3 style={{ fontSize: "1.2rem", marginBottom: "5px" }}>{paymentDetails.phone}</h3>
                <h3 style={{ fontSize: "1.2rem", marginBottom: "5px" }}>{paymentDetails.customerCode ? `ID ${paymentDetails.customerCode}` : ""}</h3>
            </div>
            <div className="address">
                <p>{paymentDetails.address}</p>
            </div>

            <div className="receipt-section-payment">
           
                <span className="receipt-section-bill-title">Payment  Date<br/> <span className="receipt-section-bill-value">{paymentDetails.paymentDate}</span></span>
                <span className="receipt-section-bill-title">Payment  Account<br/><span className="receipt-section-bill-value">{paymentDetails.paymentAccount}</span></span>

            </div>

            <div className="payment-receipt-status">
                    <span>Payment Received </span>
                    <span>৳{paymentDetails.paidAmount}</span>
            </div>

            <div className="payment-details">
           
                {paymentDetails.unpaidAmount > 0 && (
                    <div className="row-unpaid unpaid">
                        <span>Unpaid ৳{paymentDetails.unpaidAmount}</span>
                    </div>
                )}
                <p style={{'textAlign':'center'}}>in words: {convertNumberToWords(paymentDetails.paidAmount)} taka</p>
                <div className="recept-footer">
                  
                    <p>
                        Last Payment: {paymentDetails.lastPaymentDate} | ৳
                        {paymentDetails.lastPaymentAmount}
                    </p>
                </div>

                <div className="text-center" style={{ marginTop: "20px" }}>
                    <QRCodeSVG value={qrCodeValue} size={100} />
        
                </div>
            </div>
        </div>
             
        </>
    );
};

export default PaymentDetails;
