import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { QRCodeSVG } from 'qrcode.react';
import { toPng } from "html-to-image";
import { saveAs } from "file-saver";

const BillDetails = () => {
  const { id } = useParams();
  const printRef = useRef(); // Ref for the element to print
  const [billDetails, setBillDetails] = useState({
    customer: { name: "", id: "", code: "", address: "" },
    billNo: "",
    billMonth: "",
    billYear: "",
    billDate: "",
    total: "",
    previousDue: "",
    dueAmount: "",
    items: [],
  });

  useEffect(() => {
    getBillDetails(id);
  }, [id]);

  const getBillDetails = (billId) => {
    fetch(`${process.env.REACT_APP_API_URL}bill_details/${billId}`, {
      method: "GET",
    })
      .then((resp) => resp.json())
      .then((data) => {
        setBillDetails({
          customer: data.customer,
          billNo: data.billNo,
          billMonth: data.billMonth,
          billYear: data.billYear,
          billDate: data.billDate,
          total: Number(data.total),
          previousDue: Number(data.previousDue),
          dueAmount: Number(data.dueAmount),
          items: data.bill_item,
          lastPaymentAmount : data.lastPaymentAmount,
          lastPaymentDate: data.lastPaymentDate
        });
      })
      .catch((error) => console.error("Error fetching bill details:", error));
  };

  const { customer, billNo, billMonth, billYear, billDate, total, dueAmount, previousDue, items,lastPaymentDate,lastPaymentAmount } = billDetails;

  const qrCodeValue = `https://billing.netbeebd.com/bill-details/${billNo}`;
  // Function to handle printing

  const handlePrint =  () =>{
    const originalContents = document.body.innerHTML;
    const printContents = printRef.current.innerHTML;

    // Temporarily replace body contents with the div to print
    document.body.innerHTML = printContents;
    window.print();

    // Restore original contents
    document.body.innerHTML = originalContents;
    window.location.reload(); // Reload to re-render the page properly
    
  }

  const handleDownload = () => {
    const node = printRef.current;
    const containerWidth = window.innerWidth;
    toPng(node, { width: containerWidth, height: node.offsetHeight }) // Match container width
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = `${billDetails.billNo}_bill.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((error) => console.error("Error generating image:", error));
  };

  return (
    <>
           {/* Print Button */}
      <div className="text-center" style={{ marginBottom: "20px",display:'flex',flexDirection: 'row',justifyContent:'center' }}>
    
        <button
          onClick={handleDownload}
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
        ><span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
                src={toAbsoluteUrl("/media/svg/icons/Design/Image.svg"
                )}
             ></SVG>
             </span>
        </button>
        <button
          onClick={handlePrint}
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-1"
        ><span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
                src={toAbsoluteUrl("/media/svg/icons/Devices/Printer.svg"
                )}
             ></SVG>
             </span>
        </button>
      </div>
      <div className="card card-custom receipt-div" ref={printRef} >
        {/* Header Section */}
        <div className="text-center mb-4">
        <SVG
                src={toAbsoluteUrl("/media/logo.svg"
                )}
             width="110" ></SVG>
         
        </div>

        <div className="receipt-section-top-head">
      
            <h3 style={{ fontSize: "1.2rem", marginBottom: "5px" }}>{customer.name}</h3>
          
        </div>
        <div className="receipt-section-top">
            <h3 style={{ fontSize: "1.2rem", marginBottom: "5px" }}>{billNo}</h3>
            <h3 style={{ fontSize: "1.2rem", marginBottom: "5px" }}>{customer.mobileNo}</h3>
            <h3 style={{ fontSize: "1.2rem", marginBottom: "5px" }}>{customer.code ? `ID ${customer.code}` : ""}</h3>
        </div>
        {/* Customer Information */}
        <div style={{ borderBottom: "1px solid #ddd", paddingBottom: "10px", marginBottom: "15px" }}>
          
          <p style={{ textAlign: "center" }}>{customer.address}</p>
        </div>

        <div className="receipt-section-bill">
            <span className="receipt-section-bill-title">Bill Month<br/> <span className="receipt-section-bill-value">{`${billMonth} ${billYear}`}</span></span>
            {/* <span className="receipt-section-bill-title">Invoice<br/><span className="receipt-section-bill-value">{billNo}</span></span> */}
            <span className="receipt-section-bill-title">Bill Date<br/><span className="receipt-section-bill-value"> {billDate}</span></span>
        </div>

        <div className="receipt-section-payment">
           
            <span className="receipt-section-bill-title">Monthly Free<br/> <span className="receipt-section-bill-value">{customer.monthlyFee}</span></span>
            <span className="receipt-section-bill-title">Previous Due<br/><span className="receipt-section-bill-value">{previousDue}</span></span>

        </div>


        {/* Items Table */}
        {/* <div>
          <table style={{ width: "100%", borderCollapse: "collapse", marginBottom: "15px" }}>
            <thead>
              <tr>
                <th style={{ textAlign: "left", padding: "8px", borderBottom: "2px solid #ddd" }}>Sl.</th>
                <th style={{ textAlign: "left", padding: "8px", borderBottom: "2px solid #ddd" }}>Item</th>
                <th style={{ textAlign: "left", padding: "8px", borderBottom: "2px solid #ddd" }}>Quantity</th>
                <th style={{ textAlign: "left", padding: "8px", borderBottom: "2px solid #ddd" }}>Price</th>
                <th style={{ textAlign: "left", padding: "8px", borderBottom: "2px solid #ddd" }}>Amount</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index}>
                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{index + 1}</td>
                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{item.item.itemName}</td>
                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>{item.quantity}</td>
                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>৳{item.price}</td>
                  <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>৳{item.price * item.quantity}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="4" style={{ textAlign: "right", padding: "8px", fontWeight: "bold" }}>Total</td>
                <td style={{ padding: "8px", fontWeight: "bold" }}>৳{total}</td>
              </tr>
            </tfoot>
          </table>
        </div> */}

        {/* Net Payable Section */}
        <div className="text-center" style={{ fontSize: "1.2rem", color: "#EC2227", fontWeight: "bold", marginTop: "20px" }}>
          Total Bill Amount  ৳{Number(previousDue) + Number(total)}
        </div>

        <div className="recept-footer">
                  
                  <p>
                      Last Payment: {lastPaymentDate} | ৳
                      {lastPaymentAmount}
                  </p>
        </div>
        <div className="text-center" style={{ marginTop: "20px" }}>
          <QRCodeSVG value={qrCodeValue} size={100} />
        
        </div>
      </div>

   
    </>
  );
};

export default BillDetails;
