import React from 'react';
import { Link } from 'react-router-dom';

function CustomerDetail({ customers }) {
    return (
        <div className="customer-details col-md-12 col-sm-12">
            {customers.map((customer) => (
                <CustomerCard key={customer.id} customer={customer} />
            ))}
        </div>
    );
}

function CustomerCard({ customer }) {
    return (
        <Link to={`/customer-details/${customer.id}`}>
        <div className="customer-card ">
            <div className="customer-header">
                <span>ID {customer.code}</span>
                <span>{customer.name}<br/>
                    {customer.mobileNo}
                </span>
                <span>Balance ৳{customer.balance}</span>
            </div>
            <div className="customer-info">
                <p>
                    {customer.address}
                </p>
            </div>
        </div>
        </Link>
    );
}
     


export default CustomerDetail;
